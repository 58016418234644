import Tracking from "../components/Tracking";
import logo from "../assets/logo.png";
import CustomerDetail from "../components/CustomerDetail";
import { JourneyTrackerProps } from "../types";
import "../css/journey.css";

function JourneyTracker({ data }: JourneyTrackerProps) {
  const currentStatus = data.trackingInfo.find((info) => info.current);
  return (
    <div className="journey-container">
      <div className="d-flex v-center" style={{ padding: "20px 0" }}>
        <div className="flex-1 text-center">
          <img src={logo} className="logo" alt="ashs" />
        </div>
        <div className="flex-1 text-center">
          <h1 className="fw-500">Journey Tracker</h1>
        </div>
      </div>
      <div className="container">
        {currentStatus ? (
          <div className="customer_detail">
            <CustomerDetail
              customerDetail={data.customerDetail}
              currentStatus={currentStatus}
            />
          </div>
        ) : null}
        <div className="tracking_info">
          <Tracking trackingInfo={data.trackingInfo} />
        </div>
      </div>
    </div>
  );
}

export default JourneyTracker;
