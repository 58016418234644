import { Route, Routes } from "react-router-dom";
import SalesOrderCancelled from "./pages/SalesOrderCancelled";
import "./css/app.css";
import Tracker from "./pages/Tracker";
function App() {
  return (
    <Routes>
      <Route path="/:token" element={<Tracker />} />
      <Route
        path="/sales-order-cancelled/:id"
        element={<SalesOrderCancelled />}
      />
    </Routes>
  );
}

export default App;
