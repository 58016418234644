import { Timeline } from "antd";
import TrackerCard from "./TrackerCard";
import current from "../assets/current.png";
import dot from "../assets/tick.png";
import close from "../assets/close.png";
import { TrackingProps } from "../types";

function Tracking({ trackingInfo }: TrackingProps) {
  const items = [];
  for (const item of trackingInfo) {
    const pendingItem = {
      dot: <div className="tracking-dot tracking-inactive" />,
      children: item.statusOf,
      className: "reached",
    };
    const completedItem = {
      dot: item.current ? (
        <img
          src={current}
          className="tracking-dot tracking-dot-active"
          alt="active-ashs-status"
        />
      ) : (
        <img src={dot} className="tracking-dot" alt="tick" />
      ),
      children: (
        <TrackerCard
          title={item.statusOf}
          message={item.message}
          date={item.date}
        />
      ),
    };
    // Showing corresponding status accorind to the error and warning status

    const errorItem = {
      dot: item.current ? (
        <img
          src={close}
          className="tracking-dot tracking-dot-error"
          alt="error-ashs-status"
        />
      ) : (
        <img src={dot} className="tracking-dot" alt="tick" />
      ),
      children: (
        <TrackerCard
          className={item.current ? "error" : ""}
          title={item.statusOf}
          message={item.message}
          date={item.date}
        />
      ),
    };

    const warningItem = {
      dot: item.current ? (
        <img
          src={current}
          className="tracking-dot tracking-dot-warning"
          alt="warning-ashs-status"
        />
      ) : (
        <img src={dot} className="tracking-dot" alt="tick" />
      ),
      children: (
        <TrackerCard
          className={item.current ? "warning" : ""}
          title={item.statusOf}
          message={item.message}
          date={item.date}
        />
      ),
    };

    // Hide the tracker for error status
    if (item.warning) {
      items.push(warningItem);
    } else if (item.error) {
      items.push(errorItem);
      break;
    } else if (item.pending) {
      items.push(pendingItem);
    } else {
      items.push(completedItem);
    }
  }

  return <Timeline mode="left" items={items} />;
}

export default Tracking;
