import { CustomerDetailProps } from "../types";
import getCurrentStatusImage from "../utils/getCurrentStatusImage";

function CustomerDetail(customerDetailProps: CustomerDetailProps) {
  const { customerDetail, currentStatus } = customerDetailProps;
  const currentStatusImage = getCurrentStatusImage(currentStatus.statusOf);
  let displayValue;

  if (currentStatus.operation_type !== "delete") {
    displayValue = `${
      currentStatus.schedule_date
        ? currentStatus.schedule_date
        : currentStatus.date
    } ${currentStatus.time}`;
  } else {
    displayValue = "N/A";
  }
  return (
    <div className="status-container">
      <div className="text-center">
        {currentStatusImage && (
          <img src={currentStatusImage} alt="orderPlace" />
        )}
        <h1 style={{ margin: "10px 0" }}>{currentStatus.statusOf}</h1>
        <h2 className="fw-300">
          {/* Refractor the code as per the appointment deleted. */}
          {displayValue}
        </h2>
      </div>
      <div className="consumer-detail">
        <div className="d-flex">
          <h4>Customer:</h4>
          <h4>{customerDetail.name}</h4>
        </div>
        <div className="d-flex">
          <h4>Email:</h4>
          <h4>{customerDetail.email}</h4>
        </div>
        <div className="d-flex">
          <h4>Workorder Number:</h4>
          <h4>{customerDetail.workOrderNumber}</h4>
        </div>
        {/* <div className="d-flex">
          <h4>Regional Install Manager:</h4>
          <h4>{customerDetail.regionalInstallManager}</h4>
        </div> */}
      </div>
    </div>
  );
}

export default CustomerDetail;
