import { Button } from "antd";

function Acknowledge({ close }) {
  return (
    <div className="sales_thanks_modal">
      <div className="sales_thanks">
        <h2 className="sales_ack_title">Thank you</h2>
        <div className="divider"></div>
        <div className="sales_ack_body">
          <h4 className="mv-10 fw-400">
            Your scheduled appointment has been canceled
          </h4>
          <Button type="primary" onClick={close} className="mt-10">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Acknowledge;
