import { logo } from "../assets";
import "../css/sales.css";
import "../css/common.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import client from "../api/client";
import ErrorPage from "./Error";
import Header from "../components/Header";
import Splash from "./Splash";
import Footer from "../components/Footer";
import Acknowledge from "./Acknowledge";

interface SalesCancel {
  number: string;
  first_name: string;
  last_name: string;
  date: string;
  time: string;
  cst_first_name: string;
  cst_last_name: string;
  city: string;
  trimDspId: string;
}

function SalesOrderCancelled() {
  const location = useLocation();
  const [data, setData] = useState<SalesCancel>();
  const [ack, setAck] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  //  Calling acknowledge api to acknowledge the sales cancelled appointment by installer
  async function acknowledge() {
    try {
      setLoading(true);
      await client.post(location.pathname);
      setAck(true);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await client.get(location.pathname);
        setData(res.data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, [location.pathname]);

  return (
    <div className="main-container">
      <Header />
      <div className="content">
        {ack && <Acknowledge close={() => setAck(false)} />}
        {loading && <Splash title="Please wait..." />}
        {!loading && !error && data && (
          <div className="sales_container">
            <div className="sales_info">
              <div className="text-center mv-20">
                <img src={logo} alt="logo" />
              </div>
              <hr />
              <div className="sales_body">
                <h2 className="fw-700 text-center mb-10">
                  Your Appointment Scheduled Has Been Canceled
                </h2>
                <h3 className="fw-400 mv-20">
                  {/* Showing Full Name as per discussed*/}
                  Hi{" "}
                  <strong>
                    {data.first_name} {data.last_name}
                  </strong>
                  ,
                </h3>
                <h3 className="mv-20 fw-400">
                  Your appointment scheduled for{" "}
                  {new Date(data.date).toLocaleDateString()} at {data.time} in{" "}
                  {data.city} with {data.cst_first_name} {data.cst_last_name}{" "}
                  has now been canceled. If this is an error, don't hesitate to
                  get in touch with our call center at{" "}
                  <a href={`tel:${data.number}`}>{data.number}</a>.
                </h3>

                <h3 className="mv-30 fw-400 sales_ack">
                  Please <span onClick={acknowledge}>Click Here</span> to
                  acknowledge that you have received this message.
                </h3>

                <h3 className="mv-10 fw-400">
                  Thank you, <br /> American Standard
                </h3>
              </div>
              <hr />
            </div>
          </div>
        )}
        {!loading && error && <ErrorPage error={error?.message} hide />}
      </div>
      <Footer />
    </div>
  );
}

export default SalesOrderCancelled;
