type TrackerCardProps = {
  title: string;
  message: string;
  date: string;
  className?: string;
};

function TrackerCard({ title, message, date, className }: TrackerCardProps) {
  return (
    <div className={`tracker-card-container ${className ? className : ""}`}>
      <h3 style={{ width: "calc(100% - 75px)" }}>
        <strong>{title}</strong>
      </h3>
      <h4 dangerouslySetInnerHTML={{ __html: message }} />
      <h5>{date}</h5>
    </div>
  );
}

export default TrackerCard;
