import logo from "../assets/logo.png";
import Loading from "../components/Loading";
import "../css/splash.css";

interface SplashProps {
  title?: string;
}

function Splash({ title }: SplashProps) {
  return (
    <div className="splash-content">
      <img src={logo} alt="splash-logo" />
      <h1 className="fw-500">{title || "Journey Tracker"}</h1>
      <Loading title="Loading..." />
    </div>
  );
}

export default Splash;
