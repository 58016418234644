import logo from "../assets/logo.png";
function ErrorPage({ error, hide }: any) {
  return (
    <div className="error-container">
      <img src={logo} alt="splash-logo" />
      {!hide ? <h1 className="fw-500">Journey Tracker</h1> : null}
      <h2 className="fw-300 text-center">
        <span>Cannot load page at this time.</span> <br />
        <span>Please try again later.</span> <br />
        <span>{error}</span> <br />
      </h2>
    </div>
  );
}

export default ErrorPage;
