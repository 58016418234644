const currentStatusImage: any = {
  "Initial Request": require("../assets/Initial Request.png"),
  "Sales Appt. Set": require("../assets/Sales Appt. Set.png"),
  "Sales Appt.": require("../assets/Sales Appt.png"),
  "Order Processed": require("../assets/Order Placed.png"),
  "Order Shipped": require("../assets/Order Shipped.png"),
  "Order Received": require("../assets/Order Received.png"),
  "Sales Order Cancellation Request": require("../assets/Sales Order Cancelled.png"),
  "Installation Order Canceled": require("../assets/Install Order Cancelled.png"),
  "Post Install Survey": require("../assets/Post Install Survey.png"),
  "Installation Appt. Set": require("../assets/Installation Appt. Set.png"),
  "Installation Appt.": require("../assets/Installation Appt.png"),
  "Order Complete": require("../assets/Order Complete.png"),
  "Permit Requested": require("../assets/Permit Requested-Approved.png"),
  "Permit Required": require("../assets/Permit Required.png"),
  "Permit not Required": require("../assets/Permit Not Required.png"),
  "Permit on Hold": require("../assets/Permit On-Hold.png"),
  "Permit Approved": require("../assets/Permit Requested.png"),
  "Permit Submitted": require("../assets/Permit Requested-Approved.png"),
  "Permit Required : Customer Documentation Needed": require("../assets/Customer Doc.png"),
  "Permit Reviewed": require("../assets/Permit Reviewed.png"),
};
export default function getCurrentStatusImage(statusOf: string) {
  return currentStatusImage[statusOf];
}
