import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Splash from "../pages/Splash";
import JourneyTracker from "../pages/JourneyTracker";
import ErrorPage from "../pages/Error";
import Header from "../components/Header";

import client from "../api/client";
import { useParams } from "react-router-dom";

function Tracker() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState(null);
  const params = useParams();

  useEffect(() => {
    async function fetchTrackingData() {
      setLoading(true);
      var token = params.token;
      try {
        const res = await client.get(`/tracking/${token}`);
        if (!res.data.trackingInfo) {
          throw new Error("No Tracking data found");
        }
        setData(res.data);
      } catch (error) {
        console.log(error);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    }
    fetchTrackingData();
  }, [params.token]);
  return (
    <div className="main-container">
      <Header />
      <div className="content">
        {loading && <Splash />}
        {!loading && data && <JourneyTracker data={data} />}
        {!loading && error && <ErrorPage error={error.message} />}
      </div>
      <Footer />
    </div>
  );
}

export default Tracker;
