import "../css/footer.css";
function Footer() {
  const year: number = new Date().getFullYear();

  return (
    <div className="footer-container">
      <h5>
        ©{year} American Standard. PART OF LIXIL.{" "}
        <span>
          <a
            target="_blank"
            href="mailto:labs@americanstandard.com"
            rel="noopener noreferrer"
          >
            Contact Us.
          </a>
        </span>
      </h5>
    </div>
  );
}

export default Footer;
