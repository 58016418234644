import loading from "../assets/loading.png";
import "../css/loading.css";
type LoadingProps = {
  title: string;
};

function Loading({ title }: LoadingProps) {
  return (
    <div className="loading-container">
      <h2 className="fw-300">{title}</h2>
      <img src={loading} className="loading" alt="loading" />
    </div>
  );
}

export default Loading;
